// Override default variables before the import
$body-bg: #343a40;

.navbar {
  margin-bottom: 10px;
}

.jumbotron {
  background-color: #fff !important;
}

.brew-link {
  color: #fff;
  font-size: 1.8rem;
}

.homeScreenText {
  padding-left: 1.5rem;
  font-size: 1.5rem;
  &:first-child {
    padding-top: 1.5rem;
  }
}

// desktop
@media only screen and (min-width: 769px) {
  .carousel-item {
    min-height: 150px;
  }

  .carousel-caption {
    margin-bottom: 0.5%;
    padding: 25px;
  }

  .projectImageCard {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  .projectTitle {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .projectDescription {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
}

// tablet/phone
@media only screen and (max-width: 768px) {
  .carousel-item {
    min-height: 150px;
  }

  .carousel-caption {
    margin-bottom: 1%;
    padding: 25px;
  }

  .carousel-caption h5 {
    font-size: 20px;
  }

  .carousel-caption p {
    font-size: 12px;
  }
}

// small phone
@media only screen and (max-width: 426px) {
  .homeScreenText {
    font-size: 1.2rem;
    padding-left: 1.2rem;
  }

  .brew-link {
    font-size: 1.2rem;
  }

  .blockquote-footer {
    margin-top: 5%;
  }

  .carousel-item {
    min-height: 250px;
  }

  .carousel-caption {
    margin-bottom: 5%;
    padding: 25px;
  }

  .carousel-caption h5 {
    font-size: 16px;
  }

  .carousel-caption p {
    font-size: 12px;
  }
}

// Import Bootstrap and its default variables
@import "../node_modules/bootstrap/scss/bootstrap";